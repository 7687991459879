body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

.rst__placeholder {
  width: 100% !important;
  max-width: initial !important;
}

.rst__rowTitle {
  margin-right: 5px;
}

.ui.segment.fake-segment {
  padding: 0;
  border: 0;
}

.ui.breadcrumb {
  display: block !important;
  margin-bottom: 20px !important;
}

.ui.disabled.input {
  opacity: 1 !important;
}
.ui.form.field :disabled {
  opacity: 1 !important;
}

.ui.statistics {
  width: 100% !important;
  margin: 20px 0 !important;
}

/* Devices CSS */
.alignItemCenter {
  align-items: center !important;
}
.ui.dropdown .menu > .item > .label.deviceBadge {
  float: right;
  margin-right: 0;
  margin-left: 5px;
  margin-top: -3px;
}
.deviceTypeFilter.ui {
  height: 38px;
  line-height: 37px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0 15px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deviceTypeFilter.ui.simple.dropdown .menu {
  width: 100%;
}
.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.ui.segment.fake-segment {
  box-shadow: none;
}
.overflowVisible {
  overflow: visible !important;
}
.pt-10 {
  padding-top: 10px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center !important;
}
.d-fx {
  display: flex;
}

.device-hierarchy-iocn {
  width: 10px;
  height: auto;
  display: inline-block !important;
  margin-right: 8px;
  margin-left: 4px;
}

.dropzone {
  max-height: 120px;
  border: #ccc solid 1px;
  max-width: 120px;
  height: 120px;
  width: 120px;
  position: relative;
}
.dropzone p {
  width: 40px;
  height: 40px;
  background: #e0e1e2;
  font-size: 41px;
  margin: auto;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  cursor: pointer;
}

.dropzone-Image {
  max-height: 120px;
  max-width: 120px;
  height: 120px;
  width: 120px;
  position: relative;
}
.dropzone-Image img {
  max-height: 120px;
  max-width: 120px;
  height: 120px;
  width: 120px;
  border: 1px solid #ccc;
}

.dropzone-Image i {
  position: absolute;
  z-index: 1;
  right: -10px;
  top: -10px;
  background: #e0e1e2;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  color: #ce3b3b;
  cursor: pointer;
}

.location-fields {
  position: relative;
  margin-bottom: 30px;
}

.location-fields .location-close-btn {
  position: absolute;
  top: 21px;
  right: 0;
  font-size: 13px;
  padding: 4px !important;
}

.welcome-axIt {
  height: 100vh;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.full-height-container {
  max-width: 700px;
  display: flex;
  align-items: center !important;
  margin: auto !important;
  height: 100%;
}
.custom-form {
  width: 100%;
  padding-top: 30px;
  text-align: left;
}

.thumbs-container {
  display: "flex";
  flex-direction: "row";
  flex-wrap: "wrap";
  margin-top: 16;
}

.thumb-inner {
  display: "flex";
  min-width: 0;
  overflow: "hidden";
}

.img-preview {
  display: "block";
  width: "auto";
  height: "100%";
}
.default-img-preview {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}
.custom-grid-width {
  width: 90%;
}
.location-fields-wrap {
  margin-bottom: 30px;
  position: relative;
}
.location-fields-wrap .close-btn {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
  padding: 4px !important;
}

.resend-btn {
  padding: 5px !important;
}
.block-text-right {
  display: block;
  padding: 10px 0;
  text-align: right;
}
.container-center-wrap {
  width: 40%;
  margin: auto;
  max-width: 100%;
}
.container-center-wrap h3 {
  text-align: center;
}
.container-center-wrap .button {
  display: block;
  margin: auto;
}
.container-center-wrap .ui.segment {
  padding: 40px;
}
@media (max-width:768px) {
  .container-center-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ui.form {
    width: 100%;
  }
}
.fix-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.sidebar-menu {
  height: 100%;
  display: flex;
  overflow-y: unset;
  flex: 0 0 210px;
}
.page-content-wrap {
  padding: 20px;
  width: 100%;
  flex: 1 1 auto;
  min-width: 0;
  height: 100%;
  overflow: auto;
}